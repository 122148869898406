






















import { Component, Vue } from 'vue-property-decorator';
import { VSnowContainer } from 'vsnow';

import { User } from '@/store/models/User';
import Layout from '@/components/Layout.vue';
import Header from '@/components/Header/Header.vue';
import CongratulationsModal from '@/components/modals/CongratulationsModal.vue';
import { AuthModule, DepartmentsModule } from '@/store/modules';

@Component({
  components: {
    Layout,
    Header,
    CongratulationsModal,
    VSnow: VSnowContainer,
  },
})
export default class App extends Vue {
  private mods: Modificator = { theme: 'dark' };

  private blockName: string = 'app';

  @AuthModule.namespace.State('currentUser') private currentUser: User;

  @AuthModule.namespace.Getter('isAuthenticated') private isAuthenticated: AuthModule['isAuthenticated'];

  @AuthModule.namespace.Getter('isManagementStaff') private isManagementStaff: AuthModule['isManagementStaff'];

  @AuthModule.namespace.Getter('isStudent') private isStudent: AuthModule['isStudent'];

  @DepartmentsModule.namespace.Action('getDepartments')
  private getDepartments: DepartmentsModule['getDepartments'];

  snowOptions = {
    color: '#dee4fd',
    size: 8,
    fall_speed: 4.1,
    density: 35,
  };

  private mounted(): void {
    if (this.isAuthenticated) {
      const {
        needCongratulate,
      } = this.currentUser;
      if (this.isStudent && needCongratulate) {
        this.$modal.show('congratulations');
      }
      if (this.isManagementStaff) {
        this.getDepartments();
      }
    }
  }
}
