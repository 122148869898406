export enum RolesEnum {
  internalAdmin = 'internalAdmin',
  admin = 'admin',
  unitlead = 'unitlead',
  headOfDevDepartment = 'headOfDevDepartment',
  techlead = 'techlead',
  manager = 'manager',
  developer = 'developer',
  qa = 'qa',
  qaLead = 'qaLead',
  curator = 'curator',
  trainee = 'trainee',
  designer = 'designer',
  saleManager = 'saleManager',
  bookkeeper = 'bookkeeper',
  hrManager = 'hrManager',
  officeManager = 'officeManager',
  eventManager = 'eventManager',
  prManager = 'prManager',
  notApproved = 'notApproved',
  headOfAcademy = 'headOfAcademy',
  networkEngineer = 'networkEngineer',
  headOfPRDepartment = 'headOfPRDepartment',
  headOfDesignerDepartment = 'headOfDesignerDepartment',
  headOfSaleDepartment = 'headOfSaleDepartment',
  headOfBookkeeperDepartment = 'headOfBookkeeperDepartment',
  headOfHRDepartment = 'headOfHRDepartment',
  userXGroup = 'userXGroup',
  analytic = 'analytic',
  devOps = 'devOps',
  accountManager = 'accountManager',
  headOfProject = 'headOfProject',
  headOfUnit = 'headOfUnit',
  headOfGroup = 'headOfGroup',
  headOfProjectOfficeDepartment = 'headOfProjectOfficeDepartment',
  teamLead = 'teamLead',
  riseupStatistics = 'riseupStatistics',
  riseupExaminer = 'riseupExaminer',
}

export interface Role {
  id: string;
  name: string;
  slug: RolesEnum;
  description: string;
}

export const roles = <const>[
  'internalAdmin',
  'admin',
  'unitlead',
  'headOfDevDepartment',
  'techlead',
  'manager',
  'developer',
  'qa',
  'qaLead',
  'curator',
  'trainee',
  'designer',
  'saleManager',
  'bookkeeper',
  'hrManager',
  'officeManager',
  'eventManager',
  'prManager',
  'notApproved',
  'headOfAcademy',
  'networkEngineer',
  'headOfPRDepartment',
  'headOfDesignerDepartment',
  'headOfSaleDepartment',
  'headOfBookkeeperDepartment',
  'headOfHRDepartment',
  'userXGroup',
  'analytic',
  'devOps',
  'accountManager',
  'headOfProject',
  'headOfUnit',
  'headOfGroup',
  'headOfProjectOfficeDepartment',
  'teamLead',
  'riseupStatistics',
  'riseupExaminer',
];
